import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import logo from "../img/logo.png";
import api, {API_URL} from "../utils/api";
import sha256 from 'crypto-js/sha256';
import {toast_error} from "../utils/toast_helper";
import axios from "axios";
import {parse, stringify} from "qs";
import {NavLink} from "react-router-dom";

export function Accueil() {
    const {register, handleSubmit, reset} = useForm({
        defaultValues: {pays: "SN"},
    });

    const [token, setToken] = useState("");
    const [paying, setPaying] = useState(false);
    const email = 'partenairetest@copay.com';
    const password = 'siSncvDN1aRPkb4qbp34rPofkVjiiPw4HWNdx20a2esO82t9OP3KGgYKVTnsrmZCxtcz9q44yvSPeDyIk8pLL8t1XPVIGjTw8eyelEw5DIWsNUGLCARgqkVtT4kXXu7nYdlVJ1jdlG1HR0gZAkV1LYaZSYfLV2DmI5wfgTiIbi13I6NIDfXafo3bUwVpnqAD6ucUnXkJ';
    const secretKey = '123';
    const plateformeId = '2acc0315-e546-4522-ac97-d6c3cd8994a3';
    const x_api_key = '1e03b3f7-9172-4ee6-b4b4-d7831b92223a';


    const login = useCallback(async () => {
        await api.post("auth/login", {email, password}).then((data) => {
            // console.log(data)
            setToken(data.payload.token.accessToken);
        }).catch((error) => {
            alert("Nous sommes temporairement indIsponibles pour les tests...")
            setTimeout(function () {
                alert("Veuillez rééssayer plutard svp..")
            }, 2000);
        });
    }, []);

    useEffect(() => {
        if (token === "")
            login();
        const input = document.getElementById("form-montant");
        if (input !== null) {
            input.onchange = function () {
                if (input.value === "") {
                    input.className = "input";
                } else {
                    input.className = "input not-empty";
                }
            };
        }
    }, []);
    // useEffect(() => {
    //     if (token !== "")
    //         console.log("MON token : " + token);
    // }, [token]);

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const onSubmit = useCallback(async (data) => {
        if (parseInt(data.montant) % 50 === 0 && (data.pays === "SN" || data.pays === "ML")) {
            // console.log(data);
            // console.log(token);
            setPaying(true);

            var today = new Date();
            today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            let montant = parseInt(data.montant);
            const tokenToSend = sha256(secretKey + montant + today).toString();
            // console.log(tokenToSend);

            const defaults_direct_link = {
                baseURL: API_URL,
                headers: () => ({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-COUNTRY': data.pays,
                    'Token': tokenToSend,
                    'x-api-key': x_api_key,
                    'accept': '*/*',
                    // 'SERVIPROXY-API-KEY': getStoredAuthToken() ? `${getStoredAuthToken()}` : ''
                }),
                error: {
                    code: 'INTERNAL_ERROR',
                    message: 'Something went wrong. Please check your internet connection or contact our support.',
                    status: 503,
                    data: {},
                },
            };
            const api_direact_link = (method, url, variables) =>
                new Promise((resolve, reject) => {
                    axios({
                        url: `${defaults_direct_link.baseURL}${url}`,
                        method,
                        headers: defaults_direct_link.headers(),
                        params: method === 'get' ? variables : undefined,
                        data: method !== 'get' ? variables : undefined,
                        paramsSerializer: {
                            encode: parse,
                            serialize: stringify,
                        },
                    }).then(
                        response => {
                            resolve(response.data);
                        },
                        error => {
                            if (error.response) {
                                reject(error.response.data.error);
                            } else {
                                reject(defaults_direct_link.error);
                            }
                        },
                    );
                });

            var idExt = today + '-' + new Date().getHours() + new Date().getMinutes() + new Date().getSeconds() + generateRandomString(10);
            // console.log(idExt)

            var datatoSend = {
                "montantPaye": montant,
                "paiementDate": today,
                "plateformeId": plateformeId,
                "idTransactionExt": idExt,
                "commentaire": "",
                "typeTransaction": "Merchand",
                "partnerCallbackUrl": 'https://webhook.site/717a7cee-df77-43d7-ba2f-23ae0e96fc01',
                "redirectUrl": window.location.href
            }

            await api_direact_link('post', 'api/v1/gichet-unique-api/create-payment', datatoSend).then((data) => {
                var redirectLink = data.payload.paymentLink.toString();
                redirectLink = redirectLink.replace('https://copay.com', 'https://payment.copay.partners');
                setTimeout(function () {
                    window.location.href = redirectLink;
                }, 2000);
            }).catch((error) => {
                alert("Nous sommes temporairement indosponibles pour les tests...")
                setTimeout(function () {
                    alert("Veuillez rééssayer plutard svp..")
                }, 2000);
            });

        }
    }, [token]);
    return (
        <React.Fragment>
            <div
                className="position-absolute h-100 w-100 d-flex justify-content-between align-items-center flex-column px-3 px-md-5">
                <a className="chillax text-center dark-copay-blue text-decoration-none mb-3"
                   href={"https://copay.sn/"}>Retour sur le site de <span
                    className="copay-blue">COPAY</span></a>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 d-flex align-items-center flex-column">
                        <h3 className="chillax-semi-bold mb-2 text-center">Testez ici la solution de paiement COPAY</h3>
                        <p className="chillax">Décidez-vous à encaisser vos paiements partout dans le monde avec la
                            solution ultime de paiement.
                            Testez par vous-même et vivez l'expérience de paiement de vos clients.</p>
                        <hr className="dark-copay-blue w-100" style={{"height": "5px"}}/>
                        {paying ?
                            <div className="mb-5">
                                <div className="loader">
                                    <div className="loader__bar"></div>
                                    <div className="loader__bar"></div>
                                    <div className="loader__bar"></div>
                                    <div className="loader__bar"></div>
                                    <div className="loader__bar"></div>
                                    <div className="loader__ball"></div>
                                </div>
                            </div>
                            :
                            <form onSubmit={handleSubmit(onSubmit)} className="w-75 chillax">
                                <div className="mb-4">
                                    <div className="wave-group">
                                        <input required type="number" id="form-montant" min="100" step="50"
                                               {...register("montant")}
                                               className="input"/>
                                        <span className="bar"></span>
                                        <label className="label">
                                            <span className="label-char" style={{"transitionDelay": "0.05s"}}>M</span>
                                            <span className="label-char" style={{"transitionDelay": "0.10s"}}>o</span>
                                            <span className="label-char" style={{"transitionDelay": "0.15s"}}>n</span>
                                            <span className="label-char" style={{"transitionDelay": "0.25s"}}>t</span>
                                            <span className="label-char" style={{"transitionDelay": "0.30s"}}>a</span>
                                            <span className="label-char" style={{"transitionDelay": "0.35s"}}>n</span>
                                            <span className="label-char" style={{"transitionDelay": "0.40s"}}>t</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">PAYS</label>
                                    <select className="form-select" aria-label="pays" {...register("pays")} required>
                                        <option value="SN">Sénégal</option>
                                        <option value="ML">Mali</option>
                                        {/*<option value="3">Three</option>*/}
                                    </select>
                                </div>
                                <div className="mb-3 pt-3">
                                    <button type="submit" className="chillax-semi-bold">Tester COPAY</button>
                                </div>
                            </form>
                        }
                        <hr className="dark-copay-blue w-100" style={{"height": "5px"}}/>
                        <p className="chillax fs-6"><span className="text-danger">Note importante :</span> Veuillez
                            noter que les tests de paiement sont réels ! votre compte bancaire ou mobile money
                            seront automatiquement débités à hauteur du montant renseigné pendant le test.</p>
                        <img src={logo} className="w-25 mb-5"/>
                    </div>
                </div>
                <h5 className="chillax text-center dark-copay-blue mt-3">2023 © All rights reserved by COPAY</h5>
            </div>
        </React.Fragment>
    );
}
