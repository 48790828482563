import {NavLink} from "react-router-dom";

export function Navbar() {
    return <></>;
    //   return <h5 className="chillax  dark-copay-blue">2023 © All rights reserved by COPAY</h5>;
    //
    //   return (
    //   <div>
    //     <ul className="text-center list-unstyled py-3">
    //       <li>
    //         <NavLink className="chillax dark-copay-blue text-decoration-none" to={{pathname: "https://copay.sn/"}} target="_blank">Retour sur le site de <span className="copay-blue">COPAY</span></NavLink>
    //       </li>
    //       {/*<li>*/}
    //       {/*  <NavLink to="/contact">Contact</NavLink>*/}
    //       {/*</li>*/}
    //     </ul>
    //   </div>
    // );
}
