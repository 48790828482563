import axios from 'axios';
import {parse, stringify} from 'qs';
// import {getStoredAuthToken} from "./token";

export const API_URL = 'https://core.copay.partners/';
// export const API_URL = 'http://localhost:3000/';
const defaults = {
    baseURL: API_URL,
    headers: () => ({
        'Content-Type': 'application/json',
        // Authorization: getStoredAuthToken() ? `Bearer ${getStoredAuthToken()}` : undefined,
        'X-COUNTRY': 'SN',
        // 'SERVIPROXY-API-KEY': getStoredAuthToken() ? `${getStoredAuthToken()}` : ''
    }),
    error: {
        code: 'INTERNAL_ERROR',
        message: 'Something went wrong. Please check your internet connection or contact our support.',
        status: 503,
        data: {},
    },
};

const api = (method, url, variables) =>
    new Promise((resolve, reject) => {
        axios({
            url: `${defaults.baseURL}${url}`,
            method,
            headers: defaults.headers(),
            params: method === 'get' ? variables : undefined,
            data: method !== 'get' ? variables : undefined,
            paramsSerializer: {
                encode: parse,
                serialize: stringify,
            },
        }).then(
            response => {
                resolve(response.data);
            },
            error => {
                if (error.response) {
                    reject(error.response.data.error);
                } else {
                    reject(defaults.error);
                }
            },
        );
    });

export default {
    get: (...args) => api('get', ...args),
    post: (...args) => api('post', ...args),
    put: (...args) => api('put', ...args),
    patch: (...args) => api('patch', ...args),
    delete: (...args) => api('delete', ...args),
};
